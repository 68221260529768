export const updateCommissionsDetail = () => {
    const commissionWrapper = document.querySelector(".vehicle-detail__commission");

    if (commissionWrapper) {
        let { 
            vehiclePrice, 
            commission, 
            commissionIsPercentage 
        } = commissionWrapper.dataset;
        commissionIsPercentage = (commissionIsPercentage === "True")

        const commissionAmount = commissionIsPercentage ? (vehiclePrice * parseFloat(commission)) / 100 : parseFloat(commission);
        const ivaAmount = Math.round((commissionAmount * 19) / 100);
        const totalPriceAfterCommission = parseFloat(vehiclePrice) - commissionAmount - ivaAmount;

        // Referencias al DOM
        const ivaText = commissionWrapper.querySelector(".iva-text"); 
        const priceHtmlElement = commissionWrapper.querySelector(".detail__price span"); 
        const ivaHtmlElement = commissionWrapper.querySelector(".detail__iva span"); 
        const commissionHtmlElement = commissionWrapper.querySelector(".detail__commission span"); 
        const finalPriceHtmlElement = commissionWrapper.querySelector(".detail__final-price strong"); 

        // Actualiza el DOM con los datos
        // ivaText.innerText = `La comisión para el vendedor es de ${commissionIsPercentage? `${commission}%` : `$${commission}`} + IVA`;
        finalPriceHtmlElement.innerText = `$${formatPrice(vehiclePrice)}`;
        priceHtmlElement.innerText = `$${formatPrice(totalPriceAfterCommission)}`;
        commissionHtmlElement.innerText = `$${formatPrice(commissionAmount)}`;
        ivaHtmlElement.innerHTML = `$${formatPrice(ivaAmount)}`;

        // Show/hide detail
        const detail = commissionWrapper.querySelector(".detail");
        const textlink = commissionWrapper.querySelector(".textlink"); 
        textlink.addEventListener("click", () => {
            detail.classList.toggle("active")
            textlink.innerText = detail.classList.contains('active') ? 'Ocultar detalle' : 'Ver detalle';
        });
    }
};

const formatPrice = (price) => {
    // Formatea el precio agregando puntos y comas
    return price.toString().replace(/\D/g, "").replace(/(\d)(\d{3})$/, '$1.$2').replace(/(?=(\d{3})+(\D))\B/g, '.')
}
